.xs-language-options {
    white-space: normal;
}


@media (min-width: 0px) {
    #divContent > .panel {
        margin-top: 10px;
    }
    .xs-main-content {
        width: 100%;
        
        padding: 10px;

        // font-size: 10pt !important;
        line-height: normal;
        // color: #666;
        font-family: Verdana, Arial, sans-serif;

        li {
            list-style-position: inside;
        }
        ul li {
            list-style-position: outside;
        }

        #divContent, #pnlContent {
           
        }

        /**
         * Removes top margin from first occuring phrasing elements
         */
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > *:first-child,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > p:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > h1:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > h2:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > h3:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > h4:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > h5:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > h6:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > ul:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > ol:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-child > font:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > *:first-child,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > p:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > h1:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > h2:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > h3:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > h4:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > h5:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > h6:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > ul:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > ol:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > font:first-of-type,
        .xs-page-content tbody > tr:first-child > td:first-child > div:first-child > div:first-child > *:first-child {
            margin-top: 0;
        }

        /**
         * Page content spacing
         */
        .xs-page-content {
            // padding-top: 10px;

            div {
                //Fixes issue with images in old content overflowing into the widgets
                box-sizing: content-box;
            }
            blockquote 
            {
               margin: auto;
               font-size: 8pt;
               border-left: none;
            }
        }

        /**
         * Table display issue
         */
        .xs-page-content:first-child:last-child,
        .xs-dynamic-content-manager .xs-page-content:first-child:last-child {
            display: table;
            margin-top: 10px;
            width: 100%;
        }

        /* <table> for Dynamic Content */
        .xs-dynamic-content-manager {
            display: table;

            a {
                &:link {
                    color: blue;
                    text-decoration: underline;
                }
            }

            /* main content <td> */
            .xs-page-content {
                display: table-cell;
            }

            /* Widgets / right sidebar <td> */
            .xs-dynamic-content {
                display: table-cell;
                padding-top: 10px;

                .xs-dynamic-widget {
                    margin: auto;
                    border: none;
                    border-bottom: 1px solid;
                    border-radius: 0;
                    padding-bottom: 1em;
                    margin-bottom: 1em;
                    background: white;

                    &:last-child {
                        border-bottom: none;
                    }
            
                }/* /.xs-dynamic-widget */

                .panel-default>.panel-heading, .panel-heading {
                    background: transparent;
                    border: none;

                    .panel-title {
                        color: #666666;
                        font-weight: bold;
                        font-size: 12px;
                        text-align: center;
                        margin: 1em 0;
                    }
                }
                

                /* Forms */

                .form-group {

                    &.row {
                        margin: 0;

                        &:before, &:after {
                            display: none;
                        }
                    }

                    /* remove padding from bootstrap cols */
                    div[class*=col-] {
                        padding: 0;
                    }

                    .col-md-5 {
                        display: inline-block;
                        width: 41.66666667%;
                        vertical-align: middle;
                        float: left;

                        label {
                            font-size: smaller;
                        }
                    }

                    .col-md-7 {
                        display: inline-block;
                        width: 58.33333333%;
                        float: left;

                        span {
                            font-size: smaller;
                        }
                    }
                }

                label {
                    font-size: 11px;
                    margin-bottom: 0;
                    margin-right: 2px;
                    font-weight: normal;

                    &.small {
                        font-size: 9px;
                    }
                }

                .form-control {
                    height: auto;
                    padding: 0;
                    border-radius: 0;
                    width: auto;
                }

                /* Search Foreclosures Widget */
                #formForeclosureWidget {
                    
                    position: relative;

                    table.xs-dynamic-foreclosure-listings {
                        table-layout: fixed;
                        width: 100%;
                    }

                    .xs-dynamic-header-image {
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        top: 0;
                        left: 0;
                    }

                    .panel-title {
                        border-bottom: 1px solid #999;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                    }

                    

                    input, select {
                        width: 100%;
                        height: auto;
                        line-height: normal;
                        border-radius: 0;
                        box-shadow: none;
                        margin: 2px 0;
                    }

                    .input-sm {
                        height: auto;
                        line-height: normal;
                    }

                    .col-md-4 {
                        width: 20%;
                    }

                    .col-md-8 {
                        width: 80%;
                    }
                    
                
                }/* /#formForeclosureWidget */

                /* Mortgage Calculator */
                .xs-dynamic-mortgage-calculator {
                    .form-control {
                        width: 76px;
                    }

                    .input-group-addon.input-sm {
                        display: inline-block;
                        border-radius: 0;
                        background: transparent;
                        border: none;
                        text-align: left;
                    }

                    button {
                        width: 112px;
                        margin-left: 80px;
                    }
                }

                table {

                    tr {

                        td, th {
                            padding: 0;

                            &.col-md-4 {
                                width: auto;
                            }

                            &.col-md-8 {
                                width: 175px;
                            }
                        }
                    }
                }
            
            }/* /td.xs-dynamic-content */
        

            .panel-heading {
                padding: 0 !important;
                border-radius: 0 !important;
            }

            a img {
                border: none;
            }

            p {
                margin: 10px 0 0 0;
            }
            
            textarea {
                behavior: url(/Behaviors/maxlength.htc);
            }
            
            .action_btn {
                float: left;
                clear: both;
                background: url(/graphics/clipart/agents/btn_left.png) no-repeat;
                padding: 0 0 0 10px;
                margin: 5px 0;
            }

            .action_btn a {
                float: left;
                height: 40px;
                background: url(/graphics/clipart/agents/btn_stretch.png) repeat-x left top;
                line-height: 40px;
                padding: 0 24px;
                text-align: center;
                font-size: 17px;
                font-weight: bolder;
                text-decoration: none;

                &:link {
                    text-decoration: none;
                }

                &:visited {
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: underline;
                }

                span {
                    background: url(/graphics/clipart/agents/btn_right.png) no-repeat;
                    float: left;
                    width: 10px;
                    height: 40px;
                }
            }


            /* Bootstrap overrides kevin.thomson@alamode.com */
            .container, .panel-body {
                padding: 0;
            }

            .action_clear {
                clear: both;
            }


            .action_btn3 {
                background: url(/graphics/clipart/appraisers/action_button3.png) no-repeat;
                float: center;
                clear: both;
                width: 170px;
                height: 27px;
                font-size: 16px;
                font-color: white;
            }

            .action_btn3 a {
                float: center;
                line-height: 27px;
                text-decoration: none;
                text-align: center;
                font-family: Arial;
                font-weight: bold;
                text-shadow: black 1px 1px 1px;
            }

            .action_btn3 a:link {
              text-decoration: none;
              font-weight:bold;
            }

            .action_btn3 a:visited {
              text-decoration: none;
            }

            .action_btn3 a:hover {
                text-decoration: underline;
                color: white;
            }

            #XSCallToActionAg {
                margin-left: auto;
                margin-right: auto;
                width: 88%;
                border-collapse: collapse;
            }

            #XSCallToActionGR {
                margin-left: auto;
                margin-right: auto;
                width: 88%;
                border-collapse: collapse;
                background-color: #F9CDA2;
            }


            #XSCallToAction {
                margin-left: auto;
                margin-right: auto;
                width:88%;
                border-collapse: collapse;
                background-color: #bad9fc;
            }


            #XSCallToAction td, #XSCallToActionAg td, #XSCallToActionGR td {
                vertical-align: middle;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 10px;
                padding-left: 10px;
                font-family: Verdana, Arial, Helvetica, sans-serif;
                font-size: 10pt; 
                font-weight:bolder;
                line-height: 19px;
                margin: 0;
                color: #222;
            }

            #XSCallToAction a, #XSCallToActionAg a, #XSCallToActionGR a {
                color: #065cbb;
            }

            #XSCallToAction td.NoTxtPad,
            #XSCallToActionAg td.NoTxtPad,
            #XSCallToActionGR td.NoTxtPad {
                vertical-align: top;
                padding: 0px;
                font-size: 0px;
                line-height:0px;
                margin: 0;
                color: white;
            }

            .xscontent {
                font-size: 10pt; 
                color: black; 
                font-family: Verdana, Arial, Helvetica, sans-serif;
                font-weight: normal;

                img {
                    vertical-align: top;
                }
            }

            .xshead1 {
                font-weight: bold; 
                font-size: 14px; 
                margin: 10px 0px 0px 0px; 
                color: #1f2b3d; 
                line-height: 17px; 
                font-family: verdana, arial, Helvetica, sans-serif;
            }

            .mailmerge {
                font-weight: normal;
                font-size: 10pt;
                color: red;
                font-family: Verdana;
            }

            .h1 {
                font-weight: normal;
                font-size: 14pt;
                color: black;
                font-family: Verdana;
            }

            h1 {
                font-weight: bold; 
                font-size: 17px; 
                margin: 10px 0px 10px 0px; 
                color: #444;
                line-height: 18px; 
                font-family: Arial, Verdana, Helvetica, sans-serif;
            }

            h2 {
                font-weight: bold; 
                font-size: 13px; 
                margin: 10px 0px 10px 0px; 
                color: black;
                line-height: 14px; 
                font-family: Verdana, Arial, Helvetica, sans-serif;
            }

            .StatusText {
                font-weight: bolder;
                font-size: 10pt;
                color: red;
                font-family: Verdana,Arial, Helvetica, sans-serif
            }

            .AlertText {
                font-size: 8pt;
                color: red;
                font-family: Verdana, Arial;
            }

            .BottomTableBorder {
                border-bottom: black 1px solid;
            }

            .ToolHeader {
                font-weight: bolder;
                font-size: 12pt;
                color: black;
                font-family: Verdana,Arial, Helvetica, sans-serif;
            }

            .ControlFont {
                font-size: 10pt;
                color: black;
                font-family: Verdana, Arial;
            }

            .tableHeader {
                padding-left: 11px;
                font-weight: bold;
                font-size: 8pt;
                color: white;
                font-family: Verdana;
                background-color: #48669f
            }

            .GridHeader {
                font-weight: bold;
                background-image: url(/admin/Graphics/topnav_bkg.jpg);
                color: black;
                background-repeat: repeat-x;
            }

            .GridLeft {
                border-top: #a6e6ee 2px solid;
                border-left: #a6e6ee 2px solid;
                color: black;
                border-bottom: #82b5bb 2px solid;
            }

            .GridMiddle {
                border-top: #a6e6ee 2px solid;
                color: black;
                border-bottom: #82b5bb 2px solid;
            }

            .GridRight {
                border-right: #82b5bb 2px solid;
                border-top: #a6e6ee 2px solid;
                color: black;
                border-bottom: #82b5bb 2px solid;
            }

            .mol {
                font-size: 9px;
                color: #999;
                font-family: Verdana, Arial, Helvetica, sans-serif;
            }

            tbody {
                // font-size: 10pt;
                // color: black;
                // font-family: Verdana, Arial;
            }

            .SectionHeader {
                font-weight: bolder;
                font-size: 10pt;
                color: black;
                font-family: Verdana, Arial;
            }

            .ContentText {
                font-size: 10pt;
                color: black;
                font-family: Verdana, Arial;
            }

            .NewContentText {
                font-size: 10pt;
                color: black;
                font-family: Arial, Helvetica, Sans-Serif;
                line-height: 18px;
            }


            .NewContentTitle {
                font-size: 12pt;
                font-weight: bolder;
                color: black;
                font-family: Arial, Helvetica, Sans-Serif;
                line-height: 18px;
            }

            .GreyText {
                font-size: 10pt;
                color: #666666;
                font-family: Verdana, Arial;
            }

            .SmallGreyText {
                font-size: 8pt;
                color: #666666;
                font-family: Verdana, Arial;
            }

            .TitleHeader {
                font-weight: bolder;
                font-size: 12pt;
                color: black;
                font-family: Verdana,Arial, Helvetica, sans-serif;
            }

            a.DynContentText {
                font-size: 8pt;
                font-family: Verdana, Arial
            }
            
            .DynContentText {
                font-size: 8pt;
                font-family: Verdana, Arial;
            }

            .ColumnTitle {
                font-size: medium;
                font-family: Verdana;
                background-color: white;
            }

            .ListBarGroupHeader {
                border-right: #808080 1px solid;
                padding-right: 2px;
                background-position: 50% top;
                border-top: white 1px solid;
                padding-left: 2px;
                background-image: url(../images/gradient_head.gif);
                padding-bottom: 1px;
                border-left: white 1px solid;
                padding-top: 1px;
                border-bottom: #808080 1px solid;
                background-repeat: repeat-x;
                height: 24px;
            }

            .ListBarGroupTextHeader {
                font-weight: bold;
                font-size: 8pt;
                color: #868686;
            }

            .ListBarGroup {
                color: black;
                font-family: Verdana,Arial, Helvetica, sans-serif;
                background-color: white;
                text-align: left;
            }

            .ControlFont {
                font-size: 8pt;
                color: black;
                font-family: Verdana, Arial;
            }

            .ControlFontbold {
                font-weight: bold;
                font-size: 8pt;
                color: black;
                font-family: Verdana, Arial;
            }

            .txtInput {
                border: 1px solid #c0c0c0;
                color: black;
                font-size: 8pt;
                font-family: Verdana, Arial;
            }

            .GreenText {
                font-size: 11px;
                color: #006600;
                font-family: Verdana;
            }

            .SmallGreenText {
                font-size: 10px;
                color: green;
                font-family: Verdana;
            }

            .FormSelect {
                font-size: 10pt;
                color: black;
                font-family: Verdana, Arial;
                position: absolute;
            }

            .AlertText {
                font-size: 12px;
                color: red;
                font-family: Verdana;
            }

            .PodBoxHeader {
                font-weight: bold;
                font-size: 10pt;
                color: black;
                font-family: Verdana,Arial, Helvetica, sans-serif;
            }

            .XpButton {
                behavior: url(/Behaviors/xpbutton.htc)
            }
            
            .NoWrap {
                white-space: nowrap;
            }

            .btn {
                border-radius: 4px;
                background-color: #eee;
                border: 1px solid #b2b2b2;
                color: #4d4d4d;
                cursor: pointer;
                display: inline-block;
                font-size: 10.5px;
                font-weight: bold;
                line-height: 22px;
                overflow: hidden;
                text-decoration: none;
                padding: 0 8px;
                position: relative;
                text-decoration: none;
                white-space: nowrap;
                text-align: center;
                background-image: linear-gradient(to top, white 0%, #dbdbdb 100%);
            }

            .upload-status-message {
                display: none;
                overflow: auto;
            }

            #file-uploader-empty.hidden {
                display: none;
            }

            #file-uploader-empty {
                padding-top: 1em;
            }

            .upload-holder {
                margin: auto;
                border: 1px solid #eee;
                position: relative;
                width: 367px;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0 0 .5em rgba(0,0,0,0.5);
            }

            .upload-holder .upload-area {
                min-height: 100px;
                max-height: 200px;
                overflow-y:auto;
                padding: 1em;
            }

            .upload-holder .bar {
                background: #eee;
                padding: .5em;
                text-align: right;
            }

            .AjaxUploaderCancelAllButton {
                display: none;
            }

            .AjaxUploaderQueueTable {
                margin: 10px 0 0 0;
            }

            .AjaxUploaderProgressTable, .AjaxUploaderProgressTable * {
                max-width: 330px !important;
            }

            #Uploader1__Progress {
                overflow: hidden;
            }

            /*styles for template*/
            .home-page-headline-text {
                font-size: 18px;
                margin: 10px 0px 10px 0px; 
                color: black;
                line-height: 18px; 
                font-family: Verdana, Arial, Helvetica, sans-serif;
            }

            .image-heading-holder {
                position: relative;
                border-width: 2px;
                border-style: solid;
                border-color: white;
                margin: auto;
                width: 570px;
                height: 350px;
                overflow: hidden;
            }

            .image-heading-holder img {
                min-width: 100%;
                height: auto;
            }

            .floating-heading {
                font-size: 20px;
                position: absolute;
                top: 70%;
                right: 0;
                padding-left: 2em;
                padding-right: 2em;
                padding-top: .75em;
                padding-bottom: .75em;
                background: rgba(0,0,0,0.75);
                color: white;
            }

            .call-to-action-buttons {
                text-align: center;
            }

            .call-to-action-buttons .fl-button {
                text-align: center;
                margin: .5em;
            }

            .fl-button {
                box-shadow:inset 0px 1px 0px 0px white;
                background: linear-gradient( center top, #ededed 5%, #dfdfdf 100% );
                background-color: #ededed;
                border-radius: 6px;
                border: 1px solid #dcdcdc;
                display: inline-block;
                color: #777;
                font-family: arial;
                font-size: 15px;
                font-weight: bold;
                padding: 6px 24px;
                text-decoration: none;
                text-shadow: 1px 1px 0px white;
            }

            .fl-button:hover {
                background: linear-gradient( center top, #dfdfdf 5%, #ededed 100% );
                background-color: #dfdfdf;
            }

            .fl-button:active {
                position: relative;
                top: 1px;
            }

            .highlight-box {
                border-width: .15em;
                border-style: solid;
                padding: 1em;
                margin: 1em;
                background: #ededed;
                font-weight: bold;
                text-decoration: none;
                text-shadow:1px 1px 0px white;
            }

            .fl-home-page-content {
                font-size: 13px;
            }

            @media (max-width: 570px) {
                .image-heading-holder {
                    width: auto;
                    height: auto;
                }

                .fl-button {
                    display: block;
                }
            }
            
            .shadow {
                box-shadow: 0 2px 3px rgba(0,0,0,0.3);
            }
            
            .rounded-corners {
                border-radius: 3px;
            }
                
            .caution_on {
                display:block;
            }

            .caution_off {
                display: none;
            }

            .xs-dynamic-relatedLinks {
                position: relative;
            }

            .xs-dynamic-relatedLinks .xs-dynamic-header-image {
                position: absolute;
                top: -11px;
                left: -10px;
                width: 38px;
                height: 37px;
            }

            .xs-dynamic-relatedLinks .panel-title {
                border-width: 1px 1px 1px 0;
                border-style: solid;
                border-color: #ccc;
                height: 20px;
                line-height: 20px;
                color: #666;
                font-weight: normal;
                margin-bottom:0;
            }

            .xs-dynamic-important-links .list-group-item {
                padding: 10px 0 0 35px;
                background: url(/graphics/linktree.gif) no-repeat 10px 30%;
            }

            .xs-dynamic-important-links .list-group-item:last-child {
                background: url(/graphics/linktree-last.gif) no-repeat 10px -10px;
            }

            .xs-site-footer-site-navigation {
                margin-top:10px;
                padding:6px;
            }

            /* added styles for xs-dynamic-news per AHA WS-86-1 - Alan Gardner 2014-12-23*/
            .xs-dynamic-news h4, .xs-dynamic-news small {
                text-align:center !important;
            }
            .xs-dynamic-news small {
                display: inline-block;
            }

            .xs-dynamic-foreclosure-listings .form-group .col-md-7 input,
            .xs-dynamic-foreclosure-listings .form-group .col-md-7 select {
                width: 99%;
            }

            .xs-dynamic-lead-forms {
                text-align: center;
            }

            .xs-dynamic-open-houses p a.btn {
                font-size: 10px;
            }

            .xs-dynamic-open-houses .list-group .list-group-item p {
                font-size: 11px;
            }

            /* MyBlog.aspx and BlogPost.aspx */
            .xs-my-blog .xs-blog-content {
                width: 70%;
                float: left;
                position: relative;
                min-height: 1px;
                padding-left: 5px;
                padding-right: 5px;
                box-sizing: border-box;
                border-right: 1px solid;
            }

            .xs-my-blog .xs-blog-sidebar
            {
                width: 30%;
                float: left;
                position: relative;
                min-height: 1px;
                padding-left: 5px;
                padding-right: 5px;
                box-sizing: border-box;
            }

            .text-danger {
                color: red;
            }

            .xs-blog-form {
                padding: 5px;
            }

            .xs-blog-form h3 {
                text-align: center;
                border-bottom: 1px solid;
                padding-bottom: 10px;
            }

            .xs-blog-form .form-group label {
                width: 30%;
                display: inline-block;
                text-align: right;
                vertical-align: top;
            }

            .xs-blog-form .form-group {
                margin:10px;
            }

            .xs-blog-form .form-group input[type="text"], .xs-blog-form .form-group textarea {
                width: 60%;
                display: inline-block;
            }

            .xs-img-verify {
                padding-left: 30%;
            }

            .xs-blog-comment-submit {
                padding-left: 31%;
                padding-bottom: 10px;
            }

            .text-center {
                text-align:center;
            }

            .text-right {
                text-align: right;
            }

            .xs-dynamic-social-media-sites .xs-social-icon {
                float: left;
                width: 31%;
                margin: 0 1%; 
            }

            .xs-dynamic-open-houses {
                text-align: center;
            }

            .xs-dynamic-open-houses ul li h5 {
                margin: 0 0 5px 0;
            }

            .xs-dynamic-open-houses ul li p {
                margin: 0 0 5px 0;
            }

            .xs-dynamic-open-houses ul li .xs-open-house-more-info-link {
                margin-top: 10px;
                font-size: 9px;
            }

            .xs-national-rates-table {
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;
            }

            .xs-national-rates-table tr th, .xs-national-rates-table tr td  {
                text-align: left;
                font-size: 11px;
            }

            .xs-national-rates-table tr th {
                background: #eee;
                text-align:center;

            }
            
            .xs-national-rates-table tr td {
                border:1px solid #ececec;
            }

            .dropdown-menu {
                display:none;
            }

            .open > .dropdown-menu {
                display:block;
            }

            .modal {
                display: none;
            }

            #xs-legacy-shield {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #444;
                background: rgba(0,0,0,0.5);
            }

            .btn-link {
                background: none;
                border: none;
            }

            .xs-dynamic-idx-quick-search {
                border-radius: 4px;
            }

            .xs-dynamic-idx-quick-search .panel-body {
                padding: 5px;
            }

            .xs-dynamic-idx-quick-search .form-group {
                margin-bottom: 10px;
                text-align: center;
            }

            .xs-dynamic-idx-quick-search .form-group input {
                width: 100%;
                padding: 5px;
                box-sizing: border-box;
            }

            .xs-dynamic-idx-quick-search .panel-heading {
                padding: 5px;
                color:#666;
            }

            .xs-dynamic-idx-quick-search .panel-title {
                margin: 0;
                padding: 5px;
                text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
            }
            
            .xs-dynamic-idx-quick-search .form-group select {
                width: 100%;
                box-sizing: border-box;
            }

            .xs-dynamic-idx-quick-search .form-group .input-group span {
                padding: 0 !important;
            }

            .xs-dynamic-idx-quick-search .form-group .input-group input {
                width: 32%;
                box-sizing: border-box;
                margin: 0;
                font-size: .8em;
            }

            .xs-dynamic-idx-quick-search .form-group .input-group select {
                width: 44%;
                box-sizing: border-box;
            }

            .btn-block {
                width: 100%;
                box-sizing: border-box;
            }

            .list-group {
                margin-bottom: 20px;
                padding-left: 0;
            }

            .list-group-item {
                border: none;
                position: relative;
                display: block;
                padding: 5px;
                background-color: white;
            }

            .list-group-item > .badge {
                float: right;
            }

            .list-group-item > .badge + .badge {
                margin-right: 5px;
            }

            a.list-group-item {
                color: #555;
            }

            a.list-group-item .list-group-item-heading {
                color: #333;
            }

            a.list-group-item:hover,
            a.list-group-item:focus {
                text-decoration: none;
                background-color: #f5f5f5;
            }

            a.list-group-item.active,
            a.list-group-item.active:hover,
            a.list-group-item.active:focus {
                z-index: 2;
                color: white;
                background-color: #0089cf;
                border-color: #0089cf;
            }
            a.list-group-item.active .list-group-item-heading,
            a.list-group-item.active:hover .list-group-item-heading,
            a.list-group-item.active:focus .list-group-item-heading {
                color: inherit;
            }
            a.list-group-item.active .list-group-item-text,
            a.list-group-item.active:hover .list-group-item-text,
            a.list-group-item.active:focus .list-group-item-text {
                color: #9cdeff;
            }
            
            .list-group-item-heading {
                margin-top: 0;
                margin-bottom: 5px;
            }

            .list-group-item-text {
                margin-bottom: 0;
                line-height: 1.3;
            }
            
            .xs-legacy-form-group {
                background-color: red;
            }


        }/* /.xs-dynamic-content-manager */

        /* Styles for /Apps/Forms/ControlsNew/LeadFormViewer.vb */
        /* Styles are based on Bootstrap.*/
        .xs-lead-form-viewer {
            border-color: #ccc;

            .panel-heading {
                background: transparent;
                border-color: transparent;
                color: inherit;
                padding: 0;

                .panel-title {
                    font-weight: bold;
                    font-size: 12px;
                    color: #666666;
                    margin: 24px 0 0 14px;
                }
            }

            p {
                color:#666;
            }

             .form-group {
                margin-bottom: 1em;
            }

             label.control-label {
                display: inline-block;
                vertical-align: top;
                text-align: right;
                color:#333;
            }

            .col-sm-8 {
                display: inline-block;
                width:66%;
            }

            .form-control  {
                display: block;
                width: 80%;
                padding: 6px 12px;
                font-size: 14px;
                line-height: 1.42857143;
                color: #555;
                background-color: white;
                background-image: none;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
                transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
            }

             .col-sm-3  {
                width: 25%;
            }

             .btn-primary {
                color: white;
                background-color: #337ab7 !important;
                border-color: #2e6da4 !important;
                height: 30px;
            }

            .btn {
                display: inline-block;
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.42857143;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                cursor: pointer;
                user-select: none;
                background-image: none;
                border: 1px solid transparent;
                border-radius: 4px;
                box-shadow: none;
                margin-right: 5px;
            }

            .col-sm-offset-3 {
                margin-left: 25%;
            }

            fieldset {
                background: transparent;
                border: 0;
                margin:0;
                padding: 0;
            }

            textarea.form-control {
                height: auto;
            }

            legend {
                border-bottom: 1px solid #ececec;
                display: block;
                width: 100%;
                margin: 0 0 1em;
                font-size: 12px;
                font-weight: bold;
                padding: 0 0 4px 0;
                color:#333;
            }

            label {
                font-size: 12px;
                font-weight: normal;
            }

            .hint {
                font-size:10px;
            }

            .input-sm {
                padding: 5px 10px;
                font-size: 12px;
                line-height: 1.5;
                border-radius: 3px;
            }
        }/* /.xs-lead-form-viewer */

        .xs-my-blog {
            /* MyBlog.aspx and BlogPost.aspx */

            .xs-blog-content {
                width: 70%;
                float: left;
                position: relative;
                min-height: 1px;
                padding-left: 5px;
                padding-right: 5px;
                box-sizing: border-box;
                border-right: 1px solid;

                h3 {
                    font-size: 15px;
                    font-weight: bold;
                    color: black;
                }
            }

            .xs-blog-sidebar
            {
                width: 30%;
                float: left;
                position: relative;
                min-height: 1px;
                padding-left: 5px;
                padding-right: 5px;
                box-sizing: border-box;

                .panel {
                    border: none;
                    margin: 0;
                    background: transparent;
                    border-radius: none;
                    box-shadow: none;

                    .panel-body {
                        padding: 0;

                        a {
                            text-decoration: underline;
                            /*font-size: 13.3333px;*/
                        }
                    }
                }

                .panel-heading {
                    background: transparent;
                    border-color: transparent;
                    padding: 0;

                    .panel-title {
                        font-weight: bold;
                        font-size: 10pt;
                        color: black;
                        margin: 1em 0;
                    }
                }
            }

            .text-danger {
                color: red;
            }

            .xs-blog-form {
                padding: 5px;
            }

            .xs-blog-form h3 {
                text-align: center;
                border-bottom: 1px solid;
                padding-bottom: 10px;
            }

            .xs-blog-form .form-group label {
                width: 30%;
                display: inline-block;
                text-align: right;
                vertical-align: top;
            }

            .xs-blog-form .form-group {
                margin:10px;
            }

            .xs-blog-form .form-group input[type="text"], .xs-blog-form .form-group textarea {
                width: 60%;
                display: inline-block;
            }

            .xs-img-verify {
                padding-left: 30%;
            }

            .xs-blog-comment-submit {
                padding-left: 31%;
                padding-bottom: 10px;
            }

            .text-center {
                text-align:center;
            }

            .text-right {
                text-align: right;
            }

            .xs-dynamic-social-media-sites .xs-social-icon {
                float: left;
                width: 33%;
            }

            .xs-dynamic-open-houses {
                text-align: center;
            }

            .xs-dynamic-open-houses ul li h5 {
                margin: 0 0 5px 0;
            }

            .xs-dynamic-open-houses ul li p {
                margin: 0 0 5px 0;
            }

            .xs-dynamic-open-houses ul li .xs-open-house-more-info-link {
                margin-top: 10px;
                font-size: 9px;
            }

            .xs-national-rates-table {
                width: 100%;
                border-collapse: separate;
                border-spacing: 0;
            }

            .xs-national-rates-table tr th, .xs-national-rates-table tr td  {
                text-align: left;
                font-size: 11px;
            }

            .xs-national-rates-table tr th {
                background: #eee;
                text-align:center;

            }
            
            .xs-national-rates-table tr td {
                border:1px solid #ececec;
            }

            .dropdown-menu {
                display:none;
            }

            .open > .dropdown-menu {
                display:block;
            }

            .modal {
                display: none;
            }

            #xs-legacy-shield {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #444;
                background: rgba(0,0,0,0.5);
            }

            .btn-link {
                background: none;
                border: none;
            }

            .xs-dynamic-idx-quick-search {
                border-radius: 4px;
            }

            .xs-dynamic-idx-quick-search .panel-body {
                padding: 5px;
            }

            .xs-dynamic-idx-quick-search .form-group {
                margin-bottom: 10px;
                text-align: center;
            }

            .xs-dynamic-idx-quick-search .form-group input {
                width: 100%;
                padding: 5px;
                box-sizing: border-box;
            }

            .xs-dynamic-idx-quick-search .panel-heading {
                padding: 5px;
                color:#666;
            }

            .xs-dynamic-idx-quick-search .panel-title {
                margin: 0;
                padding: 5px;
                text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
            }
            
            .xs-dynamic-idx-quick-search .form-group select {
                width: 100%;
                box-sizing: border-box;
            }

            .xs-dynamic-idx-quick-search .form-group .input-group span {
                padding: 0 !important;
            }

            .xs-dynamic-idx-quick-search .form-group .input-group input {
                width: 32%;
                box-sizing: border-box;
                margin: 0;
                font-size: .8em;
            }

            .xs-dynamic-idx-quick-search .form-group .input-group select {
                width: 44%;
                box-sizing: border-box;
            }

            .btn-block {
                width: 100%;
                box-sizing: border-box;
            }

            .list-group {
                margin-bottom: 20px;
                padding-left: 0;
            }

            .list-group-item {
                border: none;
                position: relative;
                display: block;
                padding: 5px;
                background-color: white;
            }

            .list-group-item > .badge {
                float: right;
            }

            .list-group-item > .badge + .badge {
                margin-right: 5px;
            }

            a.list-group-item {
                color: #555;
            }

            a.list-group-item .list-group-item-heading {
                color: #333;
            }

            a.list-group-item:hover,
            a.list-group-item:focus {
                text-decoration: none;
                background-color: #f5f5f5;
            }

            a.list-group-item.active,
            a.list-group-item.active:hover,
            a.list-group-item.active:focus {
                z-index: 2;
                color: white;
                background-color: #0089cf;
                border-color: #0089cf;
            }
            a.list-group-item.active .list-group-item-heading,
            a.list-group-item.active:hover .list-group-item-heading,
            a.list-group-item.active:focus .list-group-item-heading {
                color: inherit;
            }
            a.list-group-item.active .list-group-item-text,
            a.list-group-item.active:hover .list-group-item-text,
            a.list-group-item.active:focus .list-group-item-text {
                color: #9cdeff;
            }
            
            .list-group-item-heading {
                margin-top: 0;
                margin-bottom: 5px;
            }

            .list-group-item-text {
                margin-bottom: 0;
                line-height: 1.3;
            }
            
            .xs-legacy-form-group {
                background-color: red;
            }
        }/* /.xs-my-blog */

        /* Subscribe Form */
        #divSubscribe {
            border: none;

            .panel-heading {
                background-color: transparent;
                border: none;
            }

            .panel-body {
                font-size: 8pt;
            }

            .form-group {
                input[type=text] {
                    width: 66%
                }

                label {
                    width: 32%;
                }
                .col-sm-6, .col-sm-3 {
                    float: none;
                    width: 100%;
                    margin: 0 0 10px 0;
                }
            }

            #imgDVSub {
                background: transparent;
                border: none;
                padding: 0;

                img {
                    padding-left: 33%;
                }
            }

            .xs-blog-comment-submit {
                padding-left: 34%;

                input {
                    border-radius: 4px;
                    background-color: #eee;
                    border: 1px solid #b2b2b2;
                    color: #4d4d4d;
                    cursor: pointer;
                    display: inline-block;
                    font-size: 10.5px;
                    font-weight: bold;
                    line-height: 22px;
                    overflow: hidden;
                    text-decoration: none;
                    padding: 0 8px;
                    position: relative;
                    text-decoration: none;
                    white-space: nowrap;
                    text-align: center;
                    background-image: linear-gradient(to top, #FFFFFF 0%, #DBDBDB 100%);
                }
            }
        }

        /* Mobile-Friendly Services Template */
        .mf-services {
            max-width: 555px;
        }

    }/* /.xs-main-content */

    #dialog .xs-lead-form-viewer {
            border-color: #7A7A7A;
            margin: auto;
            width: 700px;

            .panel-heading, #btnSubmit, #Skip {
                background-color: #7A7A7A;
                border-color: #7A7A7A;
            }

            #Skip {
                width: 50px;
                margin-right: 15px;
            }

        }
}